<template>
  <div class="custom-demo-view" style="padding: 1rem;">
    <div style="background: #FFFFFF;padding-left: 20px;margin-bottom: 10px;">
      <el-tabs v-if="reload" v-model="tabIndex" @tab-click="jump">
        <el-tab-pane :label="tabs[0].title" v-if="tabs[0].isShow" name="0"></el-tab-pane>
        <el-tab-pane :label="tabs[1].title" v-if="tabs[1].isShow" name="1"></el-tab-pane>
        <el-tab-pane :label="tabs[2].title" v-if="tabs[2].isShow" name="2"></el-tab-pane>
        <el-tab-pane :label="tabs[3].title" v-if="tabs[3].isShow" name="3"></el-tab-pane>
        <el-tab-pane :label="tabs[4].title" v-if="tabs[4].isShow" name="4"></el-tab-pane>
        <el-tab-pane :label="tabs[5].title" v-if="tabs[5].isShow" name="5"></el-tab-pane>
        <el-tab-pane :label="tabs[6].title" v-if="tabs[6].isShow" name="6"></el-tab-pane>
      </el-tabs>
    </div>
    <div ref="scrollContent" class="scroll-content recordContent" @scroll="onScroll" :style="'overflow-x: hidden; overflow-y: auto;height:' + contentStyleObj.height">
      <el-row class="substanceName">
        <el-col :span="24" style="padding-left:15px;">
          <el-popover v-show="drugData.dkbReferenceInfoList.length>0" placement="top" title="来源引用信息"
                      width="200" trigger="click"
          >
            <div>
              <p v-for="item in drugData.dkbReferenceInfoList"
                 :key="'drugNameReferenceInfos'+item.id"
              >
                {{ '[' + item.resourceTypeName + ']' }}{{ item.resourceName || '' }}
              </p>
            </div>
            <i class="el-icon-info" slot="reference" title="点击查看来源引用"></i>
          </el-popover>
          <span style="margin-right: 5px;">{{ drugData.commonName }}(id:{{ drugData.id }})</span>
          <code-icon  :iconCode="drugData.classifyIcon"  :size="26"/>
          <code-icon
            v-for="(item, index) in classifyIcons"
            :key="index"
            :iconCode="item"
            :size="26"
            style="margin-left: 5px;" />
<!--          <span v-if="drugData.classifyDesc" style="font-size: 12px;color: #2D5AFA;border-radius: 50%;border: 1px solid #2D5AFA;padding: 2px 4px;">{{ drugData.classifyDesc | classifyDescChoice }}</span>-->
        </el-col>
      </el-row>
      <!-- 药物别名 -->
      <div :ref="tabs[0].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>药物别名</span>
          </el-col>
          <el-col style="padding-left:30px;" v-if="drugData.otherNameS.length>0">
            <ul>
              <li class="substanceLi" v-for="item in drugData.otherNameS">
                <el-popover v-show="item.dkbReferenceInfoList.length>0" placement="top" title="来源引用信息"
                            width="200" trigger="click"
                >
                  <div>
                    <p v-for="j in item.dkbReferenceInfoList"
                       :key="'drugNameReferenceInfos'+j.id"
                    >
                      {{ '[' + j.resourceTypeName + ']' }}{{ j.resourceName || '' }}
                    </p>
                  </div>
                  <i class="el-icon-info" slot="reference" title="点击查看来源引用"></i>
                </el-popover>
                <!--                <i class="el-icon-info" title="点击查看来源引用"></i>-->
                <span style="margin-right: 5px;">{{ item.alias }}</span>
                <code-icon  :iconCode="item.aliasLangueIcon"  :size="25"/>
<!--                <svg-icon v-show="item.aliasLangue === 100001" icon-class="china" style="width: 24px;height: 16px;"/>-->
<!--                <svg-icon v-show="item.aliasLangue === 100002" icon-class="yingguoguoqi" style="width: 24px;height: 16px;"/>-->
              </li>
            </ul>
          </el-col>
          <el-col v-else>
            <span class="noDataClass">暂无数据</span>
          </el-col>
        </el-row>
      </div>
      <!-- 药物成分 -->
      <div :ref="tabs[1].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>药物成分</span>
          </el-col>
          <el-col class="substanceTable" style="width:600px">
            <el-table
              :data="drugData.dkbDrugIngredientList"
              border
              stripes>
              <el-table-column
                label="成分物质"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span class="linkToInfo" @click="toSubstanceInfo(scope.row.substanceId)">{{ scope.row.substanceName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="substanceTypeDesc"
                label="成分类型"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 药物组 -->
      <div :ref="tabs[2].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>药物组</span>
          </el-col>
          <el-col style="padding-left:30px;" v-if="drugData.dkbDrugGroupMappingList.length>0">
            <ul>
              <li class="substanceLi drugGroupName" v-for="(item,index) in drugData.dkbDrugGroupMappingList" :key="index">
                <span  @click="toDrugGroupInfo(item)">{{ item.drugGroupName }}</span>
              </li>
            </ul>
          </el-col>
          <el-col v-else>
            <span class="noDataClass">暂无数据</span>
          </el-col>
        </el-row>
      </div>
      <!-- 药物分类 -->
      <div :ref="tabs[3].refName" class="scroll-item">
        <el-row class="substanceStructure">
            <el-col :span="24" class="substanceTitle">
              <span>药物分类</span>
            </el-col>
            <el-col :span="24" v-if="typeDataList.length>0" style="margin-left: 20px;">
              <div
                v-for="(item,index) in typeDataList"
                :key="index"
                class="fl classify-li"
                style="display:inline-block;margin: 0 0px 5px 10px;float: left;">
                <el-tree
                  :data="item"
                  :props="defaultProps"
                  empty-text="暂无数据"
                  node-key="id"
                  class="classify-tree"
                  :expand-on-click-node="false"
                  default-expand-all>
                </el-tree>
              </div>
            </el-col>
            <el-col v-else>
              <span class="noDataClass">暂无数据</span>
            </el-col>
          </el-row>
      </div>
      <!-- 成分类似药物 -->
      <div :ref="tabs[4].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>成分类似<span class="redName">药物</span></span>
          </el-col>
          <el-col class="substanceTable" style="width: 1000px">
            <el-table
              :data="druglistData"
              border
              stripes>
              <el-table-column
                label="名称"
                width="300"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span
                    v-for="(item,index) in scope.row.dkbDrugAliasList" :key="index" >
                    <span v-if="item.isPrimary === '1'" class="linkToInfo" @click="toDrugInfo(item)">
                      {{item.alias}}
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="classifyDesc"
                label="类别"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
              <el-table-column
                label="成分"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.dkbDrugIngredientList" :key="index">
                    <span v-if="index!== 0">+</span>
                    <span class="linkToInfo" @click="toSubstanceInfo(item.substanceId)">{{item.substanceName}}</span>
                    <code-icon  :iconCode="item.substanceTypeIcon"  :size="25"/>
<!--                    <i v-if="item.substanceType===100501" class="live">活</i>-->
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 相关药品 -->
      <div :ref="tabs[5].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>相关<span class="redName">药品</span>（属于该药物的药品）</span>
          </el-col>
          <el-col class="substanceTable" style="width: 1000px">
            <el-table
              :data="drugProdlistData"
              border
              stripes>
              <el-table-column
                label="名称"
                align="left"
                width="300"
                header-align="left">
                <template slot-scope="scope">
                  <span
                    v-for="(item,index) in scope.row.dkbDpAliasList" :key="index" >
                    <span v-if="item.isPrimary === '1'" class="linkToInfo" @click="toDrugProdInfo(item)">
                      {{ item.alias }}
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="成分"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.dkbDpSpecIngredientList" :key="index">
                    <span v-if="index!== 0">+</span>
                    <span class="linkToInfo" @click="toSubstanceInfo(item.substanceId)">
                      {{item.substanceName}}
                      <span class="unit-link" v-if="item.substanceQtyMax">（{{ item.substanceQtyMax }}</span>
                      <span class="unit-link" v-if="item.substanceQtyMax">{{ item.substanceQtyUnitDesc }}）</span>
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="dkbPpInfo.preparationForm"
                label="剂型"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
<!--              <el-table-column-->
<!--                prop="dkbPpInfo.routeName"-->
<!--                label="用药途径"-->
<!--                width="150">-->
<!--              </el-table-column>-->
              <el-table-column
                prop="date"
                label="规格包装"
                width="150"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.dkbPdProdList" :key="index">
                     <span v-if="scope.row.dkbPpInfo.showSpec">{{ scope.row.dkbPpInfo.showSpec }}</span>
                      *
                     {{ item.packQty }}
                  {{ item.specUnitDesc }}
                  <span style="font-size: 18px;font-weight: bold;">/</span>
                  {{ item.packQtyUnitDesc }}
                     <span v-if="index!== scope.row.dkbPdProdList.length -1 ">，</span>
                    <br/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="生产厂家"
                width="180"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.dkbDpManufacturerList" :key="index">
                    {{ item.manufacturerName }}
                    <span v-if="index !== scope.row.dkbDpManufacturerList.length -1 ">，</span>
                    <br/>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 相关制剂 -->
      <div :ref="tabs[6].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>相关<span class="redName">制剂</span>（属于该药物的制剂）</span>
          </el-col>
          <el-col class="substanceTable" style="width: 1000px">
            <el-table
              :data="specProdlistData"
              border
              stripes>
              <el-table-column
                label="名称"
                align="left"
                width="300"
                header-align="left">
                <template slot-scope="scope">
                  <span class="linkToInfo" @click="toPreparationInfo(scope.row.preparationId)">{{ scope.row.preparationAlias }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="typeName"
                label="剂型"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
<!--              <el-table-column-->
<!--                prop="preparationSpecDesc"-->
<!--                label="规格"-->
<!--                width="100"-->
<!--                align="center"-->
<!--                header-align="center">-->
<!--              </el-table-column>-->
              <el-table-column
                label="规格"
                align="left"
                header-align="center">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.specList" :key="index">

                     {{ item.showSpec }}
                    <span v-if="index!== scope.row.specList.length - 1">，</span>
                    <br/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="routeName"
                label="用药途径"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  getDrugCategoryTree
} from '@/api/dkm/substance'
import {
  info,
  selectSimilarityIngredientDrugList,
  selectByDrugId,
  preparationList
} from '@/api/dkm/drug'
export default {
  name: 'drugSearch',
  components: {Treeselect },
  filters: {
    classifyDescChoice(str) {
      // console.log(str)
      // console.log(typeof str)
      // return str
      return str.substring(0,str.length-1)
    }
  },
  data() {
    return {
      classifyIcons: [], // 分类图标
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      isTreeShow:false,
      typeValue: null,
      typeDataList: [],
      drugData: {
        dkbReferenceInfoList: [],
        otherNameS: [],
        dkbDrugGroupMappingList: []
      }, // 药物info
      druglistData: [], // 含本物质药物
      drugProdlistData: [], // 含本物质药品
      specProdlistData: [], // 含本物质制剂
      reload: true,
      tabIndex: '0',
      contentStyleObj: {
        height: '100px'
      },
      tabs: [
        {
          isShow: true,
          title: '药物别名',
          refName: 'setOneRef'
        },
        {
          isShow: true,
          title: '药物成分',
          refName: 'setTwoRef'
        },
        {
          isShow: true,
          title: '药物组',
          refName: 'setThreeRef'
        },
        {
          isShow: true,
          title: '药物分类',
          refName: 'setFourRef'
        },
        {
          isShow: true,
          title: '成分类似药物',
          refName: 'setFiveRef'
        },
        {
          isShow: true,
          title: '相关药物',
          refName: 'setSixRef'
        },
        {
          isShow: true,
          title: '相关制剂',
          refName: 'setSevenRef'
        }
      ],
      scrollY: 0
    }
  },
  async activated() {
    this.classifyIcons = []
    this.getHight()
    window.addEventListener('resize', this.getHight)
    const id = this.$route.params && this.$route.params.id
    if (id) {
      await this.getDrugInfo(id)
      await this.getDrugCategoryTree(id)
      await this.selectSimilarityIngredientDrugList(id)
      await this.selectByDrugId(id)
      await this.preparationList(id)
    }
    this.$nextTick(()=> {
      document.querySelector('.recordContent').scrollTop = this.scrollY
    })
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'drugSearch') {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 记录滚动高度
        scrollContent.scrollTop = vm.$route.meta.scroll || 0
        vm.scrollY = vm.$route.meta.scroll
      })
    } else {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 不记录滚动高度
        scrollContent.scrollTop = 0
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'drugSearch') {
      const scrollContent = this.$refs.scrollContent
      this.$route.meta.scroll = scrollContent.scrollTop
    }
    next()
  },
  methods: {
    // 跳转到药物查看页面
    toDrugInfo(item){
      const durgId = item.drugId
      this.$router.push('/drug/search/' + durgId)
    },
    // 跳转到药物组查看页面
    toDrugGroupInfo(val){
      const drugGroupId = val.drugGroupId
      this.$router.push('/drugGroup/search/' + drugGroupId)
    },
    // 跳转到物质查看页面
    toSubstanceInfo(val){
      const substanceId = val
      this.$router.push('/substance/search/' + substanceId)
    },
    // 跳转到药品查看页面
    toDrugProdInfo(item){
      const drugProdId = item.drugProdId
      this.$router.push('/drugProd/view/' + drugProdId)
    },
    // 跳转到制剂查看页面
    toPreparationInfo(val){
      const preparationId = val
      this.$router.push('/preparationSpecification/view/' + preparationId)
    },
    /** 转换剂型树形数据结构 */
    normalizerTreeNode(node) {
      if (!(node.children && node.children.length > 0)) {
        delete node.children
      }

      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    getDrugCategoryTree(id) {
      // this.isTreeShow = false
      let obj = {}
      obj.dataType = 3 // 药物
      obj.dataId = id
      getDrugCategoryTree(obj).then(response => {
        let typeDataList = response.data || []
        let arr = this.classifyDataHandle(typeDataList)
        this.typeDataList = arr
        this.classifyIcons = [...new Set(this.classifyIcons)]
      })
    },
    // 处理分类的数据
    classifyDataHandle(typeDataList) {
      let arr = []
      for (let item of typeDataList) {
        if (!item.children) {
          item.children = []
          item.label = item.name
          item.value = item.id
          item.valueId = item.id
          this.classifyIcons.push(item.icon)
        } else if (item.children && item.children.length > 0) {
          this.treeselectDeep(item, item.children)
        }
        arr.push([item])
      }
      return arr
    },
    treeselectDeep(parent, item) {
      if (Array.isArray(item)) {
        // 数组
        for (let i = 0; i < item.length; i++) {
          if (item[i].children) {
            this.treeselectDeep(item[i], item[i].children)
            parent.valueId = item[i].valueId
            parent.label = item[i].label
          } else {
            parent.valueId = item[i].id
            parent.label = item[i].name
            item[i].icon && this.classifyIcons.push(item[i].icon)
          }
        }
      } else if (item?.constructor === Object) {
        // 对象
        if (item.children && !Array.isArray(item)) {
          this.a(item, item.children)
        } else if (!item.children && !Array.isArray(item)) {
          parent.valueId = parent.children[0].id
          parent.label = parent.children[0].name
        }
      }
    },
    /*根据药物id获取药物明细*/
    getDrugInfo(id) {
      info(id).then(response => {
        this.drugData = response.data
        // 通用名 isPrimary=1
        this.drugData.dkbDrugAliasList.map((item) => {
          if (item.isPrimary === '1') {
            this.drugData.dkbReferenceInfoList = item.dkbReferenceInfoList
            this.drugData.commonName = item.alias
          }
        })
        // 别名 isPrimary != 1
        let otherNameS = []
        this.drugData.dkbDrugAliasList.map((item) => {
          if(item.isPrimary !== '1') {
            otherNameS.push(item)
            // this.substanceData.commonName = item.name
          }
        })
        this.drugData.otherNameS = otherNameS
      })
    },
    // 药物:根据药物id查询成分相似药物列表
    selectSimilarityIngredientDrugList(id) {
      let obj = {}
      obj.pageNum = 1
      obj.pageSize = 100000
      obj.drugId = id
      selectSimilarityIngredientDrugList(obj).then(response => {
        this.druglistData = response.rows
      })
    },
    // 药品:根据药物id查询属于该药物的药品列表
    selectByDrugId(id) {
      let obj = {}
      obj.pageNum = 1
      obj.pageSize = 100000
      obj.drugId = id
      selectByDrugId(obj).then(response => {
        this.drugProdlistData = response.rows
      })
    },
    // 制剂:根据药物id查询属于该药物的制剂规格列表
    preparationList(id) {
      let obj = {}
      obj.pageNum = 1
      obj.pageSize = 100000
      obj.drugId = id
      preparationList(obj).then(response => {
        this.specProdlistData = response.data.rows
      })
    },
    refresh() {
      this.reload = false
      this.$nextTick(() => (this.reload = true))
    },
    // tab click
    jump(index, info) {
      let target = document.querySelector('.scroll-content')
      let scrollItems = document.querySelectorAll('.scroll-item')
      // 判断滚动条是否滚动到底部
      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.tabIndex = index.index.toString()
      }
      let totalY = scrollItems[index.index].offsetTop - scrollItems[0].offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
      let distance = document.querySelector('.scroll-content').scrollTop // 滚动条距离滚动区域顶部的距离
      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
      // 计算每一小段的距离
      let step = totalY / 50
      if (totalY > distance) {
        smoothDown(document.querySelector('.scroll-content'))
      } else {
        let newTotal = distance - totalY
        step = newTotal / 50
        smoothUp(document.querySelector('.scroll-content'))
      }

      // 参数element为滚动区域
      function smoothDown(element) {
        if (distance < totalY) {
          distance += step
          element.scrollTop = distance
          setTimeout(smoothDown.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }

      // 参数element为滚动区域
      function smoothUp(element) {
        if (distance > totalY) {
          distance -= step
          element.scrollTop = distance
          setTimeout(smoothUp.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }
    },
    // 滚动条滚动
    onScroll(e) {
      let scrollItems = document.querySelectorAll('.scroll-item')
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop - 100
        if (judge) {
          this.tabIndex = i.toString()
          break
        }
      }
    },
    getHight() {
      this.contentStyleObj.height = (window.innerHeight - 190) + 'px'
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.getHight)
  },
}
</script>
<style lang="scss">
.custom-demo-view {
  background: #EBEFF7;
  .is-left{
    padding-left: 5px!important;
  }
  .el-form-item{
    line-height: 20px!important;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0px!important;
  }

  .el-form-item__label{
    line-height: 30px!important;
  }
  .el-form-item__content{
    line-height: 30px!important;
  }
  .el-tabs__header{
    margin-bottom: 0px!important;
  }
  .classify-li {
    margin: 10px !important;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .classify-tree {
    border: 1px solid #638BF7;
    border-radius: 15px;
    padding: 0 5px;
    min-height: 52px;
  }
  .el-tree-node__content:hover, .el-tree-node__content:focus {
    background-color: transparent !important;
  }
  .el-tree-node__content {
    background-color: transparent !important;
  }
  .el-tree-node .is-current {
    background-color: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.redName {
  color: red!important;
}
.drugGroupName{
  cursor:pointer ;
}
.noDataClass{
  width: 80px;
  height: 30px;
  background: #E6ECFE;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  margin-left: 30px;
}
.linkToInfo{
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: #2B60F8;
  cursor: pointer;
}
.unit-link {
  color: #333;
  cursor: pointer;
  text-decoration: underline;
}
.live{
  font-style:normal;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: #47BC0D;
  line-height: 22px;
  text-align: center;
  width: 22px;
  height: 22px;
  border: 1px solid #47BC0D;
  border-radius: 11px;
}
.substanceName{
  height: 40px;
  line-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  span{
    font-size: 16px;
    color: #333333;
    font-weight: 600!important;
  }
}
.el-icon-info{
  color: #E99B00;
  font-size: 16px;
  margin-right:5px;
}
.substanceStructure{
  height: 40px;
  height: auto!important;
  min-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  margin: 10px 0px;
  padding-bottom: 20px;
}
.substanceTitle{
  font-size: 16px;
  font-weight: 600!important;
  padding:16px 0px 5px 19px;
  color: #333333;
}
ul,li{ padding:0;margin:0;list-style:none}
li{
  float: left;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  background: #E6ECFE;
  border-radius: 3px;
  padding:0px 10px;
}
.substanceTable{
  padding:10px 20px 0px;
  margin-left:10px;
}
</style>

